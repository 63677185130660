<template>
  <div class="columns a-topic">
    <div class="column center is-2">
      <!-- <div v-if="isEditable || featureGroup.isNew">
        <select-vue
          :value="featureGroup.feature"
          label="Feature"
          :showLabel="true"
          placeholder="Select a feature"
          :list="categoryCustomTopics"
          :submitted="submitted"
          :required="true"
          @onChangeSelection="onChangeTopicAlias"
          name="feature" />
      </div> -->
      <p class="topic-label">{{ featureGroup.feature['feature'] }}</p>
    </div>
    <div class="column center is-2">
      <input
        type="checkbox"
        :id="permissions[0]+featureGroup.index"
        :value="permissions[0]"
        :disabled="false"
        v-model="featureGroup.permissions" />
    </div>
    <div class="column center is-2">
      <input
        type="checkbox"
        :id="permissions[1]+featureGroup.index"
        :value="permissions[1]"
        :disabled="false"
        v-model="featureGroup.permissions" />
    </div>
    <div class="column center is-2">
      <input
        type="checkbox"
        :id="permissions[2]+featureGroup.index"
        :value="permissions[2]"
        :disabled="false"
        v-model="featureGroup.permissions" />
    </div>
    <div class="column center is-2">
      <input
        type="checkbox"
        :id="permissions[3]+featureGroup.index"
        :value="permissions[3]"
        :disabled="false"
        v-model="featureGroup.permissions" />
    </div>
    <div class="column center is-2">
      <input
        type="checkbox"
        :id="'all'+featureGroup.index"
        v-model="selectAll"
        :disabled="false"
        @click="selectAllFun()" />
    </div>
    <!-- <div class="column center is-1">
      <div v-if="isEditable || featureGroup.isNew" class="action-buttons">
        <b-button @click="action('update')"><div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="invisible box"><path data-name="Rectangle 147804" fill="none" d="M0 0h22v22H0z"/></g><g data-name="icons Q2"><path data-name="Path 12820" d="M6.4 17.462l-3.721-3.673a.976.976 0 01-.093-1.255.883.883 0 011.395-.093l3.068 3.068 10.97-10.97a.93.93 0 011.3 1.3L7.7 17.462a.883.883 0 01-1.3 0z" fill="#fff"/></g></svg></div></b-button>
        <b-button @click="action('cancel')"><div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="invisible box"><path data-name="Rectangle 147805" fill="none" d="M0 0h22v22H0z"/></g><g data-name="icons Q2"><path data-name="Path 12821" d="M12.338 11l5.07-5.018a1 1 0 00.1-1.29.908.908 0 00-1.434-.1l-5.066 5.066-5.066-5.066a.908.908 0 00-1.434.1 1 1 0 00.1 1.29L9.674 11l-5.066 5.018a1 1 0 00-.1 1.29.908.908 0 001.434.1l5.066-5.066 5.066 5.066a.908.908 0 001.434-.1 1 1 0 00-.1-1.29z" fill="#fff"/></g></svg></div></b-button>
      </div>
      <div v-else class="action-buttons">
        <b-button @click="action('edit')"><div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.868 3.292L12.14.552a.962.962 0 00-1.357 0l-9.674 9.66-.883 3.812a.958.958 0 00.93 1.162 1 1 0 00.2 0l3.854-.883 9.66-9.65a.962.962 0 00-.002-1.361zM4.744 13.463l-3.612.758.823-3.542 7.238-7.21 2.788 2.789zm7.861-7.879l-2.79-2.789 1.618-1.608 2.743 2.789z" fill="#eff3f6" stroke="#eff3f6" stroke-width=".4"/></svg></div></b-button>
      </div>
    </div> -->
  </div>
</template>

<script>
import SelectVue from '../../../../components/Select.vue'

export default {
  components: {
    SelectVue
  },
  props: [
    'topicDetails',
    'onUpdate',
    'onDelete',
    'submitted',
    'onRemoveTopics',
    'index',
    'categoryCustomTopics'
  ],
  data () {
    return {
      permissions: ['read', 'write', 'update', 'delete'],
      localSubmitted: false,
      openFeature: false,
      isEditable: false,
      selectAll: false,
      prevstate: {
        feature: {},
        permissions: [],
        isNew: false,
        index: null
      },
      featureGroup: {
        feature: {},
        permissions: [],
        isNew: false,
        index: null
      },
      isInValidFeature: false
    }
  },
  watch: {
    topicDetails () {
      Object.assign(this.featureGroup, this.topicDetails)
    },
    submitted () {
      this.localSubmitted = this.submitted
    },
    'featureGroup.permissions' () {
      if (this.featureGroup.permissions.length === 4) {
        this.selectAll = true
      } else {
        this.selectAll = false
      }
      this.$emit('onUpdate', this.featureGroup, this.index)
    }
  },
  mounted () {
    this.localSubmitted = this.submitted
    Object.assign(this.featureGroup, this.topicDetails)
    this.featureGroup.index = this.index
    Object.assign(this.prevstate, this.featureGroup)
  },
  methods: {
    onChangeTopicAlias (name, isValid) {
      this.featureGroup.feature = name
      this.isInValidFeature = isValid
      this.$emit('onUpdate', this.featureGroup, this.index)
    },
    selectAllFun () {
      if (this.selectAll) {
        this.featureGroup.permissions = []
      } else {
        this.featureGroup.permissions = this.permissions
      }
      this.$emit('onUpdate', this.featureGroup, this.index)
    },
    action (type) {
      // switch (type) {
      //   case 'edit':
      //     this.isEditable = true
      //     break
      //   case 'update':
      //     if (!this.isInValidFeature) {
      //       if (this.featureGroup.isNew) {
      //         this.featureGroup.isNew = false
      //       }
      //       this.isEditable = false
      //       this.localSubmitted = false
      //       Object.assign(this.prevstate, this.featureGroup)
      //     } else {
      //       this.localSubmitted = true
      //     }
      //     break
      //   case 'cancel':
      //     this.isEditable = false
      //     if (this.featureGroup.isNew) {
      //       this.$emit('onDelete', this.featureGroup, this.index)
      //     } else {
      //       this.$emit('onUpdate', this.prevstate, this.index)
      //     }
      //     break
      // }
    }
  }
}
</script>

<style lang="scss">

.columns {
  .center {
    display: flex;
    align-items: center;
  }
  .column {
    // display: flex;
    // align-items: center;
    .topic-label {
      font-size: 14px;
      font-family: Quicksand;
      color: #eff3f6;
      padding-left: 10px;
    }
    .relative {
      position: relative;
      .required-error {
        color: red;
        // position: absolute;
        font-size: 12px;
      }
    }
    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      .button {
        background-color: transparent;
        border: none;
        padding: 0px;
        height: auto;
        .flex {
          display: flex;
        }
      }
      .button:nth-child(1) {
        margin-right: 10px;
      }
    }
  }
}
.a-topic {
  background-color: #1a1e25;
  border-radius: 4px;
}
</style>
