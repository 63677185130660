<template>
    <div class="parent-bucket-container">
      <div class="columns">
        <div class="column header is-2">
            <p>Feature</p>
        </div>
        <div class="column header is-2">
            <p>Read</p>
        </div>
        <div class="column header is-2">
            <p>Write</p>
        </div>
        <div class="column header is-2">
            <p>Update</p>
        </div>
        <div class="column header is-2">
            <p>Delete</p>
        </div>
         <div class="column header is-2">
            <p>All</p>
        </div>
        </div>
        <div class="single-topic-row" v-for="(topic,index) of topicList" :key="index">
            <role-permission @onUpdate="onUpdate" :categoryCustomTopics="categoryCustomTopics" :submitted="submitted" @onDelete="onDeleteFun" @onRemoveTopics="onRemoveTopicsFun" :index="index" :topicDetails="topic" />
        </div>
    </div>
</template>

<script>
import RolePermission from './RolePermission.vue'

export default {
  components: {
    RolePermission
  },
  props: ['topicList', 'addNewTopic', 'submitted', 'onDelete', 'onRemoveTopics', 'onUpdateTopic', 'categoryCustomTopics'],
  data () {
    return {
      topics: []
    }
  },
  mounted () {
    // if (!this.topicList.length) {
    //   this.$emit('addNewTopic', { feature: null, index: null, permissions: [], isNew: true })
    // }
  },
  methods: {
    addNewTopicFun () {
      this.$emit('addNewTopic', { feature: null, index: null, permissions: [], isNew: true })
    },
    onUpdate (object, index) {
      this.$emit('onUpdateTopic', object, index)
    },
    onDeleteFun (object, index) {
      this.$emit('onDelete', object, index)
    },
    onRemoveTopicsFun (object, id) {
      this.$emit('onRemoveTopics', object, id)
    }
  }
}
</script>

<style lang="scss">
.parent-bucket-container {
  width: 100%;
  .columns {
    .header {
      p {
        font-size: 12px;
        font-family: Quicksand;
        font-weight: 500;
        color: #7f8fa4;
      }
    }
    .header:nth-child(2) {
      padding-left: 12px;
    }
  }
  padding: 0px 12px;
    .single-topic-row {
        padding: 5px 0px;
    }
    .add-new-topic {
        padding: 10px 0px 0px 0px;
        height: auto;
        background-color: transparent;
        border: none;
        p {
          font-size: 14px;
          color: #3C90F5;
          font-family:'Quicksand';
        }
    }
}
</style>
